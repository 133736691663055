import {
  Backdrop,
  Box,
  Card,
  CircularProgress,
  LinearProgress,
} from "@mui/material";
import Breadcrumbs from "@mui/material/Breadcrumbs/Breadcrumbs";
import { Container } from "@mui/system";
import React, { PropsWithChildren, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import { changeListStyle } from "../../../store/BreadCrumbSlice/BreadCrumbSlice";
import { RootState } from "../../../store/store";
import PrimarySearchAppBar from "../../Appbar/Appbar";
import CustomSeparator from "../../BreadCrumbs/BreadCrumbs";

const MainLayout: React.FC<PropsWithChildren> = () => {
  const getLoading = useSelector(
    (state: RootState) => state.breadcrumb.loading
  );
  const dispatch = useDispatch();
  useEffect(() => {
    let viewList = window.localStorage.getItem("viewList");
    if (viewList) {
      dispatch(
        changeListStyle({
          listStyle: viewList === "0" ? "horizontal" : "vertical",
        })
      );
    }
  }, []);
  return (
    <>
      <PrimarySearchAppBar />
      <LinearProgress
        color="primary"
        className={!getLoading ? "hidden-child" : ""}
      />
      <Container sx={{ mt: 4, mb: 4 }}>
        <CustomSeparator />
        <div className="mt-2 content-body">
          <Outlet />
        </div>
      </Container>
    </>
  );
};

export default MainLayout;
