import FolderIcon from "@mui/icons-material/Folder";
import AlbumIcon from "@mui/icons-material/Album";
import AndroidIcon from "@mui/icons-material/Android";
import AppleIcon from "@mui/icons-material/Apple";
import MusicNoteIcon from "@mui/icons-material/MusicNote";
import MovieFilterIcon from "@mui/icons-material/MovieFilter";
import FolderZipIcon from "@mui/icons-material/FolderZip";
import WindowIcon from "@mui/icons-material/Window";
import ImageIcon from "@mui/icons-material/Image";
import GifBoxIcon from "@mui/icons-material/GifBox";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import ArticleIcon from "@mui/icons-material/Article";
import GridOnIcon from "@mui/icons-material/GridOn";
import HelpIcon from "@mui/icons-material/Help";
export function getIcon(type: string) {
  switch (type.toLowerCase()) {
    case "folder":
      return <FolderIcon />;
    case ".iso":
      return <AlbumIcon />;
    case ".apk":
      return <AndroidIcon />;
    case ".ipa" || ".dmg":
      return <AppleIcon />;
    case ".mp3" || ".wma" || ".wav" || ".flac" || ".aac" || ".ogg":
      return <MusicNoteIcon />;
    case ".mp4" || ".avi" || "mkv" || ".flv" || ".3gp":
      return <MovieFilterIcon />;
    case ".zip":
      return <FolderZipIcon />;
    case ".exe":
      return <WindowIcon />;
    case ".png" || ".jpg" || ".jpeg":
      return <ImageIcon />;
    case ".gif":
      return <GifBoxIcon />;
    case ".pdf":
      return <PictureAsPdfIcon />;
    case ".doc" || ".docx":
      return <ArticleIcon />;
    case ".xls" || "xlsx":
      return <GridOnIcon />;
    default:
      return <HelpIcon />;
  }
}

export function getImages(type: string) {
  switch (type.toLowerCase()) {
    case ".iso":
      return "/assets/iconImage/iso.png";
    case ".apk":
      return "/assets/iconImage/apk.png";
    case ".ipa":
      return "/assets/iconImage/ipa.png";
    case ".dmg":
      return "/assets/iconImage/dmg.png";
    case ".mp3" || ".wma" || ".wav" || ".flac" || ".aac" || ".ogg":
      return "/assets/iconImage/mp3.png";
    case ".mp4" || ".avi" || "mkv" || ".flv" || ".3gp":
      return "/assets/iconImage/movie.png";
    case ".zip":
      return "/assets/iconImage/zip.png";
    case ".exe":
      return "/assets/iconImage/exe.png";
    case ".png" || ".jpg" || ".jpeg":
      return "/assets/iconImage/img.png";
    case ".gif":
      return "/assets/iconImage/gif.png";
    case ".pdf":
      return "/assets/iconImage/pdf.png";
    case ".doc" || ".docx":
      return "/assets/iconImage/word.png";
    case ".xls" || "xlsx":
      return "/assets/iconImage/excel.png";
    default:
      return "/assets/iconImage/unknown.png";
  }
}
