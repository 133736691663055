import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import BreadCrumbs from "../../models/Breadcrumb";

interface BreadCrumbState {
  breadcrumbs: BreadCrumbs[];
  listStyle: "vertical" | "horizontal";
  loading: boolean;
  fileDownload?: string | null;
}
const initialState: BreadCrumbState = {
  breadcrumbs: [
    {
      name: "Trang chủ",
      url: "",
      isActive: false,
      isRoot: true,
    },
  ],
  listStyle: "vertical",
  loading: false,
  fileDownload: null,
};
export const BreadCrumbSlice = createSlice({
  name: "breadcrumb",
  initialState,
  reducers: {
    changeBreadCrumb: (state, action: PayloadAction<BreadCrumbs[]>) => {
      state.breadcrumbs = action.payload;
      state.fileDownload = null;
    },
    changeListStyle: (
      state,
      action: PayloadAction<{ listStyle: "vertical" | "horizontal" }>
    ) => {
      state.listStyle = action.payload.listStyle;
    },
    changeLoading: (state) => {
      state.loading = !state.loading;
    },
    changeUrlFileDownload: (
      state,
      action: PayloadAction<{ fileDownload: string }>
    ) => {
      state.fileDownload = action.payload.fileDownload;
    },
  },
});

export default BreadCrumbSlice.reducer;
export const {
  changeBreadCrumb,
  changeListStyle,
  changeLoading,
  changeUrlFileDownload,
} = BreadCrumbSlice.actions;
