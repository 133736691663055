import { Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ListView from "../../Components/ListView/ListView";
import DataResponse from "../../models/DataResponse";
import DataServices from "../../services/getData";
import {
  changeBreadCrumb,
  changeLoading,
} from "../../store/BreadCrumbSlice/BreadCrumbSlice";
import { RootState } from "../../store/store";
import ResponseAPI from "../../models/ResponseAPI";

const Home: React.FC = () => {
  const dispatch = useDispatch();
  const listStyle = useSelector(
    (state: RootState) => state.breadcrumb.listStyle
  );
  const [datas, setData] = useState<DataResponse[]>([]);

  const searchQuery = useSelector((state: RootState) => state.search.query);
  useEffect(() => {
    dispatch(changeLoading());
    getData();
  }, [searchQuery]);

  const getData = async () => {
    let res: ResponseAPI<DataResponse>;
    if (searchQuery != null && searchQuery.length > 0) {
      res = await DataServices.SearchFiles("", searchQuery);
    } else {
      res = await DataServices.getFolder("");
    }

    document.title =
      res.breadCrumbs?.at(res.breadCrumbs.length - 1)?.name ?? "FILE MANAGER";

    if (res.isSuccess) {
      dispatch(changeLoading());
      setData(res.data ?? []);
      if (searchQuery != null && searchQuery.length > 0) {
        dispatch(
          changeBreadCrumb([
            {
              isActive: false,
              name: "Trang chủ",
              url: "",
              isRoot: true,
            },
            {
              isActive: true,
              name: "Tìm kiếm: " + searchQuery,
              url: "#",
              isRoot: false,
            },
          ])
        );
      } else {
        dispatch(
          changeBreadCrumb([
            {
              isActive: true,
              name: "Trang chủ",
              url: "",
              isRoot: true,
            },
          ])
        );
      }
    }
  };
  return datas.reduce((a, b) => a + b.items.length, 0) > 0 ? (
    <>
      {datas.map((data, index) => (
        <ListView
          isList={listStyle === "vertical"}
          key={index}
          items={data.items}
          type={data.type == "Folder" ? "Folder" : "Item"}
        />
      ))}
    </>
  ) : (
    <div
      className="d-flex"
      style={{ position: "absolute", height: "80%", width: "100%" }}
    >
      <Typography className="m-auto text-black-secondary">
        Không có dữ liệu
      </Typography>
    </div>
  );
};

export default Home;
