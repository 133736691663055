import {configureStore} from '@reduxjs/toolkit'
import { BreadCrumbSlice } from './BreadCrumbSlice/BreadCrumbSlice'
import {SearchSlice} from './SearchSlice/SearchSlice'

export const store = configureStore({
    reducer:{
        breadcrumb:BreadCrumbSlice.reducer,
        search: SearchSlice.reducer
    }
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch