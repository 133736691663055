import Card from "@mui/material/Card/Card";
import CardActions from "@mui/material/CardActions/CardActions";
import CardContent from "@mui/material/CardContent/CardContent";
import Grid from "@mui/material/Grid/Grid";
import Typography from "@mui/material/Typography/Typography";
import React, { useState } from "react";
import Items from "../../models/Item";
import { Link } from "react-router-dom";
import FolderIcon from "@mui/icons-material/Folder";
import { folder } from "../../routes";
import {
  Box,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { getIcon, getImages } from "../../utils/Library";
import MenuCustom from "../Menu/Menu";
import FileDownload from "@mui/icons-material/FileDownload";
import { useDispatch, useSelector } from "react-redux";
import { changeUrlFileDownload } from "../../store/BreadCrumbSlice/BreadCrumbSlice";
import { RootState } from "../../store/store";

type ListViewProps = {
  type: "Folder" | "Item";
  className?: string;
  style?: React.CSSProperties;
  items: Items[];
  isList: boolean;
};

const ListView: React.FC<ListViewProps> = (props) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [filePath, setFilePath] = useState<string>("");
  const dispath = useDispatch();

  const urlFiledownload = useSelector(
    (state: RootState) => state.breadcrumb.fileDownload
  );

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    let url = e.currentTarget.parentElement?.getAttribute("data-url") ?? "";

    setFilePath(url);
    setAnchorEl(e.currentTarget);
  };
  return props.items.length > 0 ? (
    <div>
      <Typography
        margin={2}
        fontWeight={500}
        className="text-black-secondary"
        fontSize={"14px"}
      >
        {props.type == "Folder" ? "Thư mục" : "Tệp"}
      </Typography>

      {props.type === "Item" && (
        <MenuCustom
          filePath={filePath}
          handleClose={handleClose}
          open={open}
          anchorEl={anchorEl}
        />
      )}

      <Grid
        container
        {...(props.isList
          ? { columns: { xs: 2, sm: 3, md: 3 } }
          : { columns: { xs: 4, sm: 9, md: 15 } })}
        spacing={2}
      >
        {props.items.map((item, index) => (
          <Grid key={index} item xs={2} sm={3} md={3}>
            <Link
              title={item.name}
              className={
                urlFiledownload != null && urlFiledownload == item.url
                  ? "active"
                  : ""
              }
              data-url={item.url}
              to={item.type !== "Folder" ? "#" : folder + item.url}
              onClick={() =>
                dispath(changeUrlFileDownload({ fileDownload: item.url }))
              }
            >
              {props.isList ? (
                <ListItem onContextMenu={handleClick}>
                  <ListItemIcon>{getIcon(item.type)}</ListItemIcon>
                  <ListItemText
                    sx={{ wordBreak: "break-all" }}
                    primary={item.name}
                    className="text-black-secondary"
                    secondary={
                      <>
                        <Typography
                          typography={"p"}
                          fontWeight={400}
                          fontSize={"0.845rem"}
                        >
                          Ngày cập nhật:{" "}
                          {new Date(item.lastModifier).toLocaleString("vi-VN")}
                        </Typography>
                        {item.size && (
                          <Typography
                            typography={"p"}
                            fontWeight={400}
                            fontSize={"0.845rem"}
                          >
                            Kích thước: {item.size}
                          </Typography>
                        )}
                      </>
                    }
                  />
                </ListItem>
              ) : (
                <Card
                  onContextMenu={handleClick}
                  className={`customCard ${props.type == "Folder" ?? "folder"}`}
                  variant="outlined"
                >
                  {props.type == "Item" && (
                    <CardContent className="p-2">
                      <img src={getImages(item.type)} alt="" />
                    </CardContent>
                  )}
                  <CardActions className="p-1">
                    {props.type == "Folder" && <FolderIcon className="pr-1" />}
                    <CardContent>
                      <Typography
                        className="text-black-secondary"
                        noWrap
                        overflow={"hidden"}
                        textOverflow={"ellipsis"}
                        fontWeight={"600"}
                        fontSize={"14px"}
                      >
                        {item.name}
                      </Typography>
                      {item.size && (
                        <Typography
                          className="text-black-secondary"
                          noWrap
                          overflow={"hidden"}
                          textOverflow={"ellipsis"}
                          fontWeight={"500"}
                          fontSize={"12px"}
                        >
                          {item.size}
                        </Typography>
                      )}
                    </CardContent>
                  </CardActions>
                </Card>
              )}
            </Link>
          </Grid>
        ))}
      </Grid>
    </div>
  ) : (
    <></>
  );
};

export default ListView;
