import { Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import ListView from "../../Components/ListView/ListView";
import DataResponse from "../../models/DataResponse";
import DataServices from "../../services/getData";
import {
  changeBreadCrumb,
  changeLoading,
} from "../../store/BreadCrumbSlice/BreadCrumbSlice";
import { RootState } from "../../store/store";
import ResponseAPI from "../../models/ResponseAPI";

const Folder: React.FC = () => {
  const dispatch = useDispatch();
  const listStyle = useSelector(
    (state: RootState) => state.breadcrumb.listStyle
  );
  const { hashUrl } = useParams();
  const [datas, setData] = useState<DataResponse[]>([]);
  const searchQuery = useSelector((state: RootState) => state.search.query);

  useEffect(() => {
    dispatch(changeLoading());
    getData();
  }, [hashUrl, searchQuery]);

  const getData = async () => {
    if (hashUrl !== undefined) {
      let res: ResponseAPI<DataResponse>;
      if (searchQuery != null && searchQuery.length > 0) {
        res = await DataServices.SearchFiles(hashUrl, searchQuery);
      } else {
        res = await DataServices.getFolder(hashUrl);
      }
      document.title =
        res.breadCrumbs?.at(res.breadCrumbs.length - 1)?.name ?? "FILE MANAGER";

      if (res.isSuccess) {
        if (searchQuery != null && searchQuery.length > 0) {
          dispatch(
            changeBreadCrumb([
              {
                isActive: false,
                name: "Trang chủ",
                url: "",
                isRoot: true,
              },
              {
                isActive: true,
                name: "Tìm kiếm: " + searchQuery,
                url: "#",
                isRoot: false,
              },
            ])
          );
        } else {
          dispatch(changeBreadCrumb(res.breadCrumbs ?? []));
        }
        dispatch(changeLoading());

        setData(res.data ?? []);
      }
    }
  };
  return datas.reduce((a, b) => a + b.items.length, 0) > 0 ? (
    <>
      {datas.map((data, index) => (
        <ListView
          isList={listStyle === "vertical"}
          key={index}
          items={data.items}
          type={data.type == "Folder" ? "Folder" : "Item"}
        />
      ))}
    </>
  ) : (
    <div
      className="d-flex"
      style={{ position: "absolute", height: "80%", width: "100%" }}
    >
      <Typography className="m-auto text-black-secondary">
        Không có dữ liệu
      </Typography>
    </div>
  );
};

export default Folder;
