import * as React from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { RootState } from "../../store/store";
import { useDispatch, useSelector } from "react-redux";
import { download, folder } from "../../routes";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import GridOnIcon from "@mui/icons-material/GridOn";
import { changeListStyle } from "../../store/BreadCrumbSlice/BreadCrumbSlice";
import IconButton from "@mui/material/IconButton";
import FileDownload from "@mui/icons-material/FileDownload";

export default function CustomSeparator() {
  const breadcrumbs = useSelector(
    (state: RootState) => state.breadcrumb.breadcrumbs
  );

  const listStyle = useSelector(
    (state: RootState) => state.breadcrumb.listStyle
  );

  const urlFiledownload = useSelector(
    (state: RootState) => state.breadcrumb.fileDownload
  );

  const dispatch = useDispatch();

  const handleChange = () => {
    const styles: "vertical" | "horizontal" =
      listStyle === "horizontal" ? "vertical" : "horizontal";
    window.localStorage.setItem(
      "viewList",
      styles === "horizontal" ? "0" : "1"
    );
    dispatch(changeListStyle({ listStyle: styles }));
  };

  const handleDownload = async () => {
    const url =
      process.env.REACT_APP_BASE_URL + download + "?path=" + urlFiledownload;
    var link = document.createElement("a");
    link.href = url;
    link.click();
    link.remove();
  };

  return (
    <div className="d-flex breadcumbs content-between align-center">
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
      >
        {breadcrumbs.map((item, index) =>
          item.isActive ? (
            <Typography
              key={index}
              className="breadcrum-item active"
              color="text.primary"
            >
              {item.name}
            </Typography>
          ) : (
            <Link
              key={index}
              className="breadcrum-item"
              color="inherit"
              to={(!item.isRoot ? folder : "") + item.url}
            >
              {item.name}
            </Link>
          )
        )}
      </Breadcrumbs>
      <div
        className="styleList d-flex"
        style={{ alignItems: "center", columnGap: "5px" }}
      >
        <IconButton onClick={handleDownload} disabled={urlFiledownload == null}>
          <FileDownload></FileDownload>
        </IconButton>
        <div onClick={handleChange}>
          {listStyle === "vertical" ? (
            <FormatListBulletedIcon />
          ) : (
            <GridOnIcon />
          )}
        </div>
      </div>
    </div>
  );
}
