import * as React from "react";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import Typography from "@mui/material/Typography";
import ContentCut from "@mui/icons-material/ContentCut";
import ContentCopy from "@mui/icons-material/ContentCopy";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import Cloud from "@mui/icons-material/Cloud";
import { download } from "../../routes";
import InfoIcon from "@mui/icons-material/Info";
import Menu from "@mui/material/Menu/Menu";

type MenuProps = {
  filePath: string;
  open: boolean;
  anchorEl: Element | ((element: Element) => Element) | null | undefined;
  handleClose(e: {}, reason: "backdropClick" | "escapeKeyDown"): void;
};

const MenuCustom: React.FC<MenuProps> = (props) => {
  const handleDownload = async () => {
    const url =
      process.env.REACT_APP_BASE_URL + download + "?path=" + props.filePath;
    var link = document.createElement("a");
    link.href = url;
    link.click();
    link.remove();
  };

  return (
    <Paper sx={{ width: 320, maxWidth: "100%" }}>
      <Menu
        open={props.open}
        onClose={props.handleClose}
        anchorEl={props.anchorEl}
      >
        <MenuItem>
          <ListItemIcon>
            <ContentCut fontSize="small" />
          </ListItemIcon>
          <ListItemText>Cắt</ListItemText>
          <Typography variant="body2" color="text.secondary">
            ⌘X
          </Typography>
        </MenuItem>
        <MenuItem>
          <ListItemIcon>
            <ContentCopy fontSize="small" />
          </ListItemIcon>
          <ListItemText>Sao chép</ListItemText>
          <Typography variant="body2" color="text.secondary">
            ⌘C
          </Typography>
        </MenuItem>
        <MenuItem onClick={handleDownload}>
          <ListItemIcon>
            <FileDownloadIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Tải xuống</ListItemText>
        </MenuItem>
        <Divider />
        <MenuItem>
          <ListItemIcon>
            <InfoIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Thông tin</ListItemText>
        </MenuItem>
      </Menu>
    </Paper>
  );
};
export default MenuCustom;
