import axiosClient from "../api/axiosClient";
import DataResponse from "../models/DataResponse";
import Items from "../models/Item";
import ResponseAPI from "../models/ResponseAPI";
import { download } from "../routes";

class DataServices {
  constructor() {}
  public static async getFolder(path?:string): Promise<ResponseAPI<DataResponse>> {
    const url = `/api/FileManager/GetFolder${path!=null?"?path="+encodeURIComponent(path):''}`;
    return axiosClient.get(url);
  }

  public static async SearchFiles(path:string, name: string):Promise<ResponseAPI<DataResponse>>{
    const url = `/api/FileManager/SearchFiles?path=${encodeURIComponent(path)}&name=${encodeURIComponent(name)}`;
    return axiosClient.get(url);
  }

}
export default DataServices;
