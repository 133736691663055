import { PayloadAction, createSlice } from "@reduxjs/toolkit"

interface SearchSliceState{
    query?: string
}

const initialState: SearchSliceState = {
    query: ""
}

export const SearchSlice = createSlice({
    name: "search",
    initialState,
    reducers:{
        changeSearchInput:(state,action:PayloadAction<SearchSliceState>)=>{
            state.query = action.payload.query
        }
    }
})

export default SearchSlice.reducer;
export const {changeSearchInput} = SearchSlice.actions;